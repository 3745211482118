'use client';
import { useEffect } from 'react';
import { LangageProvider } from 'common/contexts/LangageContext';
import { ThemeProvider } from 'styled-components';
import { saasModernTheme } from 'common/theme/saasModern';
import NextTopLoader from 'nextjs-toploader';
import AOS from 'aos';
import '@redq/reuse-modal/es/index.css';
import 'common/assets/css/flaticon.css';
import 'common/assets/css/icon-example-page.css';
import 'swiper/css/bundle';
import 'common/assets/css/react-slick.css';
import 'common/assets/css/rc-collapse.css';
import 'rc-collapse/assets/index.css';
import 'common/assets/css/rc-drawer.css';
import 'aos/dist/aos.css';
//import './i18n';

const CustomApp = ({ Component, pageProps }) => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Global animation duration
      once: true, // Whether animation should happen only once - while scrolling down
      mirror: false, // Whether elements should animate out while scrolling past them
    });
  }, []);
  return (
    <>
      <NextTopLoader showSpinner={false} color='#ff6e72' zIndex={10005}/>
      <LangageProvider locale={pageProps.locale}>
        <ThemeProvider theme={saasModernTheme}>
            <Component {...pageProps} />
        </ThemeProvider>
      </LangageProvider>
    </>
  );
};

export default CustomApp;

 