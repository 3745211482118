const colors = {
  transparent: 'transparent', // 0
  black: '#000000', // 1
  white: '#ffffff', // 2
  headingColor: '#302b4e',
  textColor: '#43414e', // 3
  labelColor: '#767676', // 4
  inactiveField: '#f2f2f2', // 5
  inactiveButton: '#b7dbdd', // 6
  inactiveIcon: '#EBEBEB', // 7
  primary: '#34324A', // 8
  primaryHover: '#242333', // 9
  secondary: '#F6AE2D', // 10
  redSecondary: '#ff6e72;',
  secondaryHover: '#b24d4f', // 11
  yellow: '#FFB800', // 12
  yellowHover: '#F29E02', // 13
  borderColor: '#1b1e25', //14
  primaryBoxShadow: '0px 8px 20px -6px #302b4e',
  secondaryBoxShadow: '0px 8px 20px -6px rgba(237, 205, 55, 0.5)',
  primaryLight: '#1098F7',
  primaryDark: '#24262C',
  secondaryLight: '#F4F1BB',
  scondaryDark: '#2E4057',
};

export default colors;
